



























































































































































































































import { IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { CalendarTypeEnum } from "@/lib/utility/calendarEvent";
import { detailedDateWithDay, formatHoursAndMinutes, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import RulesMixin from "@/mixins/RulesMixin.vue";
import { MrfiktivLeasingContractGen, MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IVehicle } from "@/models/vehicle.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import {
  VehicleAggregation,
  VehicleEvent,
  VehicleEventCollection,
  VehicleEventTypeEnum,
  eventTypeMap
} from "@/store/modules/fleet-aggregation.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import EventCreateDialog from "../event/EventCreateDialog.vue";
import TicketCreateDialog from "../project/TicketCreateDialog.vue";
import Calendar from "../utility/Calendar.vue";
import CardIcon from "../utility/CardIcon.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import RefsEvent from "../utility/RefsEvent.vue";
import RefsVehicle from "../utility/RefsVehicle.vue";
import SideCard from "../utility/SideCard.vue";
import TableWrapper, { IControlElements, ITableWrapperHeader } from "../utility/TableWrapper.vue";
import ContractSideCard from "./ContractSideCard.vue";
import FleetContractCrudMixin from "./FleetContractCrudMixin.vue";
import FleetVehicleDetailEventCardCalendarDetail from "./FleetVehicleDetailEventCardCalendarDetail.vue";
import FleetVehicleEventCrudMixin from "./FleetVehicleEventCrudMixin.vue";
import { VehicleModule } from "@/store/modules/vehicle.store";
import Tooltip from "../utility/tooltip.vue";
import { IReport } from "@/models/report.entity";
import { ITicket } from "@/models/ticket.entity";
import { PartnerEventTabEnum } from "@/lib/enum/partner-events-tabs.enum";

@Component({
  components: {
    CardIcon,
    ContextMenu,
    Calendar,
    RefsVehicle,
    RefsEvent,
    SideCard,
    FleetVehicleDetailEventCardCalendarDetail,
    ConfirmActionDialog,
    EventCreateDialog,
    TicketCreateDialog,
    TableWrapper,
    Tooltip,
    ContractSideCard
  },
  filters: { simpleDoubleDigitDate, formatHoursAndMinutes, detailedDateWithDay }
})
export default class FleetCalendarCard extends mixins(
  RulesMixin,
  DarkModeHighlightMixin,
  FleetVehicleEventCrudMixin,
  FleetContractCrudMixin,
  PermissionMixin
) {
  @Prop()
  value!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop()
  parsed!: VehicleEventCollection;

  @Prop({ default: () => [] })
  recurringEvents!: IEventUIDto[];

  @Prop()
  vehicleId!: string;

  @Prop()
  partnerId!: string;

  @Prop({ default: true })
  enableGrouping!: string;

  // category to group by vehicle
  readonly GROUP_BY_VEHICLE = "vehicle.id";

  // category to group by
  groupBy = "";

  readonly VehicleEventTypeEnum = VehicleEventTypeEnum;

  isVehicleId = !!this.vehicleId;

  reason = "";

  isTicketCreateDialogActive = false;

  recurringForDeletion: IEventUIDto | null = null;

  isRecurringDeleteDialogActive = false;

  filters = {
    [VehicleEventTypeEnum.MAIN_INSPECTION]: true,
    [VehicleEventTypeEnum.EVENT]: true,
    [VehicleEventTypeEnum.LEASING_START]: true,
    [VehicleEventTypeEnum.LEASING_END]: true,
    [VehicleEventTypeEnum.MILEAGE_UPDATE]: true,
    [VehicleEventTypeEnum.TICKET]: true,
    [VehicleEventTypeEnum.REPORT]: true,
    [VehicleEventTypeEnum.CREATION]: false
  };

  selectedEvent: (VehicleEvent & { vehicleId: string }) | null = null;

  calendarType = this.isMobile ? CalendarTypeEnum.DAY : CalendarTypeEnum.MONTH;

  AppointmentTypeEnum = VehicleEventTypeEnum;

  ticketRefs: MrfiktivReferenceGen[] = [];

  get calendarFocus() {
    return this.value;
  }

  set calendarFocus(cf: string) {
    this.$emit("input", cf);
  }

  get currentVehicleId() {
    return this.vehicleId;
  }

  set currentVehicleId(id: string) {
    this.$emit("update:vehicleId", id);
  }

  get getTableNameColumnWidth() {
    if (this.vehicleId) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return "40vw";
      } else {
        return "70vw";
      }
    }

    if (this.$vuetify.breakpoint.mdAndUp) {
      if (this.groupBy) {
        return "40vw";
      } else {
        return "20vw";
      }
    } else {
      if (this.groupBy) {
        return "60vw";
      } else {
        return "30vw";
      }
    }
  }

  get controlElementsRecurring(): IControlElements[] {
    return [
      {
        icon: "mdi-open-in-new",
        action: this.openEventSideCard,
        text: String(this.$t("edit"))
      },
      {
        icon: "mdi-calendar-remove",
        action: (v: IEventUIDto) => {
          this.recurringForDeletion = v;
          this.isRecurringDeleteDialogActive = true;
        },
        text: String(this.$t("remove"))
      }
    ];
  }

  get headersRecurring(): ITableWrapperHeader[] {
    return [
      {
        text: this.$t("project.project.created").toString(),
        value: "timestamp.created",
        align: "start",
        width: "120",
        type: "date"
      },
      {
        text: this.$t("components.fleet.FleetHomeCalendar.deleted").toString(),
        value: "isDeleted",
        width: "15",
        align: "start"
      },
      {
        text: this.$t("components.fleet.FleetHomeCalendar.event").toString(),
        value: "summary",
        align: "start"
      },
      { text: "", align: "end", value: "controls", width: 200, sortable: false }
    ];
  }

  get filteredEvents() {
    const filtered = [];
    for (const [cat, filters] of Object.entries(this.filters)) {
      if (filters) {
        filtered.push(...this.parsed[cat]);
      }
    }
    return filtered;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get appointmentTypeColorsMap() {
    return VehicleAggregation.appointmentTypeColorsMap;
  }

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];
    headers.push({
      text: this.$t("components.fleet.FleetHomeCalendar.start").toString(),
      value: "start",
      type: "date"
    });
    headers.push({ text: "", value: "type" });
    headers.push({ text: this.$t("components.fleet.FleetHomeCalendar.name").toString(), value: "name" });

    if (this.groupBy !== "vehicle.id" && !this.isVehicleId) {
      headers.push({ text: this.$t("common.nouns.vehicle").toString(), value: "vehicle" });
    }

    return headers;
  }

  goToPartnerEvents() {
    new GoToHelper(this.$router).goToPartnerEventsQuery(this.partnerId, PartnerEventTabEnum.LIST, this.vehicleId, true);
  }

  /**
   * Toggles the group by vehicle option in the table
   */
  toggleGroupBy() {
    if (this.groupBy === this.GROUP_BY_VEHICLE) {
      this.groupBy = "";
    } else {
      this.groupBy = this.GROUP_BY_VEHICLE;
    }
  }

  getEventDisplayConfig(event: VehicleEvent) {
    if (event.type === VehicleEventTypeEnum.EVENT) {
      const eventUiDto = event.data[0] as IEventUIDto;

      if (eventUiDto.recurringEventId) {
        return { icon: "mdi-sync", text: this.$t("views.fleet.EventCreateDialog.recurrence").toString() };
      }
    }

    return eventTypeMap.get(event.type);
  }

  getVehicleForId(vehicleId: string) {
    return VehicleModule.maps.id.get(vehicleId)[0];
  }

  getIsRecurring(event: VehicleEvent) {
    if (!(event.type === VehicleEventTypeEnum.EVENT)) return false;
    if (!(event.data[0] as IEventUIDto).recurringEventId) return false;

    return true;
  }

  simpleDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  openEventSideCard(event: IEventUIDto) {
    this.$emit("openEventSideCard", event);
  }

  async selectEvent(event: VehicleEvent) {
    this.$log.debug("selectEvent", event);

    this.selectedEvent = null;

    this.$nextTick(() => {
      const vehicleId = this.vehicleId;

      if (event.type === VehicleEventTypeEnum.EVENT && event.data.length) {
        this.openEventSideCard(event.data[0] as IEventUIDto);
      } else if (
        (event.type === VehicleEventTypeEnum.LEASING_START || event.type === VehicleEventTypeEnum.LEASING_END) &&
        event.data.length
      ) {
        this.selectedEvent = { ...event, vehicleId };
      }
    });
  }

  closeSideCard() {
    this.selectedEvent = null;
  }

  openVehicleDetail(v: IVehicle, close?: Function) {
    this.$emit("openVehicleSideCard", v);
    if (close) close();
  }

  openTicketDetail(v: ITicket, close?: Function) {
    this.$emit("openTicketSideCard", v);
    if (close) close();
  }

  openReportDetail(v: IReport, close?: Function) {
    this.$emit("openReportSideCard", v);
    if (close) close();
  }

  openContractDetail(v: MrfiktivLeasingContractGen, vehicleId: string, close?: Function) {
    this.$emit("openContractDetail", { ...v, vehicleId });
    if (close) close();
  }

  async onCreateEvent(event: IEventUIDto, close?: Function) {
    await this.createEvent(event, this.currentVehicleId, new Date(this.calendarFocus));

    if (close) close();

    this.$emit("updateEventList", this.calendarFocus);
  }

  async onDeleteEvent(event: IEventUIDto, close?: Function) {
    if (!event.vehicleId) {
      this.$log.error("No vehicleId");
      return;
    }

    await this.deleteEvent(event, event.vehicleId, new Date(this.calendarFocus));

    if (close) close();
    this.$emit("updateEventList", this.calendarFocus);
  }

  async onUpdateEvent(event: IEventUIDto, close?: Function) {
    await this.updateEvent(event, this.currentVehicleId, new Date(this.calendarFocus));

    if (close) close();
    this.$emit("updateEventList", this.calendarFocus);
  }

  async onUpdateContract(contract: MrfiktivLeasingContractGen) {
    if (!this.selectedEvent) return;
    await this.updateContract(this.partnerId, this.selectedEvent?.vehicleId, contract.id, contract);
  }

  async onRemoveContract(contract: MrfiktivLeasingContractGen) {
    if (!this.selectedEvent) return;
    await this.deleteContract(this.partnerId, this.selectedEvent?.vehicleId, contract.id);
    this.selectedEvent = null;
  }

  async setTicketRefs() {
    this.ticketRefs.splice(0);
    const vehicleId = this.selectedEvent?.vehicleId ?? this.vehicleId;

    this.ticketRefs.push({
      refId: vehicleId,
      refType: BackendResourceEnum.VEHICLE
    });

    // event refs
    const selectedEvent = this.selectedEvent;
    const eventData = selectedEvent?.data;
    if (!selectedEvent || !eventData?.length || !eventData[0]) {
      return;
    }

    switch (selectedEvent.type) {
      case VehicleEventTypeEnum.EVENT: {
        await (eventData[0] as IEventUIDto).createVirtual();
        const eventId = (eventData[0] as IEventUIDto).id;
        if (eventId) {
          this.ticketRefs.push({
            refId: eventId,
            refType: BackendResourceEnum.EVENT
          });
        }
        break;
      }
      case VehicleEventTypeEnum.TICKET: {
        const ticketId = (eventData[0] as ITicket).id;
        this.ticketRefs.push({
          refId: ticketId,
          refType: BackendResourceEnum.TICKET
        });
        break;
      }
      case VehicleEventTypeEnum.REPORT: {
        const ticketId = (eventData[0] as IReport).id;
        this.ticketRefs.push({
          refId: ticketId,
          refType: BackendResourceEnum.REPORT
        });
        break;
      }
    }
  }

  async goToEventDetail(event: IEventUIDto) {
    await event.createVirtual();

    if (!event.id) {
      this.$log.error("event id missing");
      return;
    }
    await new GoToHelper(this.$router).goToEventDetail(this.partnerId, event.id, false);
  }
}
