import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { handleError } from "@/lib/utility/handleError";
import bookingService from "@/services/booking/services/bookingService";
import {
  BookingBookingInformationDtoGen,
  BookingBookingDetailViewModelGen,
  BookingCustomerInformationDtoGen,
  BookingCustomFieldValueGen
} from "@/services/booking/v1/data-contracts";
import { IImage, Image } from "./image.entity";
import { BookingDataAccessLayer } from "@/store/modules/access-layers/booking.access-layer";
import Vue from "vue";
import { formatHoursAndMinutes, formatYearsMonthDay } from "@/lib/utility/date-helper";
import { MrfiktivShortUserViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ITimestamp, Timestamp } from "./timestamp.entity";

@IsFilterable
class BookingBase implements IEntity<IBooking>, BookingBookingDetailViewModelGen {
  id: string;

  @FilterConfig({
    displayName: "objects.booking.id",
    type: FilterTypes.OBJECT_ID
  })
  get _id() {
    return this.id;
  }

  @FilterConfig({
    displayName: "objects.booking.serviceId",
    type: FilterTypes.OBJECT_ID
  })
  serviceId?: string | undefined;

  @FilterConfig({
    displayName: "objects.booking.resourceId",
    type: FilterTypes.OBJECT_ID
  })
  resourceId: string;

  @FilterConfig({
    displayName: "objects.booking.partnerId",
    type: FilterTypes.OBJECT_ID
  })
  partnerId: string;

  @FilterConfig({
    displayName: "objects.booking.endDate",
    type: FilterTypes.DATE
  })
  endDate: string;

  @FilterConfig({
    displayName: "objects.booking.duration",
    type: FilterTypes.NUMBER
  })
  duration: number;

  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  @FilterConfig({
    displayName: "objects.booking.isDeleted",
    type: FilterTypes.BOOLEAN
  })
  isDeleted: boolean;

  deletedAt?: string | undefined;
  customerInformation?: BookingCustomerInformationDtoGen | undefined;
  values?: BookingCustomFieldValueGen[] | undefined;
  bookingInformation: BookingBookingInformationDtoGen | undefined;
  attachments?: IImage[];
  attachmentIds?: string[];

  protected _startDate = new Date(0);
  protected _endDate = new Date(0);

  /**
   * Internal string to manipulate via date picker
   */
  protected _startDateString = "";

  /**
   * Internal string to manipulate via time picker
   */
  protected _startTimeString = "";

  /**
   * Internal string to manipulate via time picker
   */
  protected _endTimeString = "";

  get start() {
    return new Date(this._startDate).getTime();
  }

  get end() {
    return new Date(this._endDate).getTime();
  }

  /**
   * Sets the start date and stores internal state
   * @param dateString YYYY-MM-DD
   */
  set startDate(dateString: string) {
    Vue.$log.debug(dateString);
    if (!dateString) {
      return;
    }

    if (!dateString.includes("-")) {
      Vue.$log.error("Cannot set date string without  -");
      return;
    }

    const [year, month, date] = dateString.split("-");
    Vue.$log.debug(year, month, date);

    this._startDate.setFullYear(+year);
    this._startDate.setMonth(+month - 1);
    this._startDate.setDate(+date);
    this._endDate.setFullYear(+year);
    this._endDate.setMonth(+month - 1);
    this._endDate.setDate(+date);
    this._startDateString = dateString;
  }

  /**
   * Returns the internal start date state
   */

  @FilterConfig({
    displayName: "objects.booking.startDate",
    type: FilterTypes.DATE
  })
  get startDate() {
    return this._startDateString;
  }

  /**
   * Sets the start time and stores internal state
   */
  set startTime(time: string) {
    Vue.$log.debug(time);
    if (!time.includes(":")) {
      Vue.$log.error("Cannot set time string without  :");
      return;
    }

    const [hours, minutes] = time.split(":");
    this._startDate.setHours(+hours, +minutes, 0, 0);

    this._startTimeString = time;
  }

  /**
   * Returns the start time internal state
   */
  get startTime() {
    return this._startTimeString;
  }

  /**
   * Sets the end time and stores internal state
   */
  set endTime(time: string) {
    Vue.$log.debug(time);
    if (!time.includes(":")) {
      Vue.$log.error("Cannot set time string without  :");
      return;
    }

    const [hours, minutes] = time.split(":");
    this._endDate.setHours(+hours, +minutes, 0, 0);

    this._endTimeString = time;
  }

  /**
   * Returns the end time internal state
   */
  get endTime() {
    return this._endTimeString;
  }

  constructor(data?: Partial<BookingBookingDetailViewModelGen>) {
    this.id = data?.id || "";
    this.serviceId = data?.serviceId;
    this.resourceId = data?.resourceId || "";
    this.partnerId = data?.partnerId || "";

    this.startDate = data?.startDate || "";
    this.endDate = data?.endDate || "";

    if (data?.start) this.startDate = formatYearsMonthDay(new Date(data.start));
    if (data?.start) this.startTime = formatHoursAndMinutes(new Date(data.start));
    if (data?.end) this.endTime = formatHoursAndMinutes(new Date(data.end));

    this.duration = data?.duration || 0;
    this.timestamp = new Timestamp(data?.timestamp);
    this.isDeleted = data?.isDeleted || false;
    this.deletedAt = data?.deletedAt;
    this.customerInformation = data?.customerInformation;
    this.bookingInformation = data?.bookingInformation || {
      notes: ""
    };
    this.attachments = (data?.attachments || []).map(b => new Image(b));
    this.attachmentIds = [];
    // Exclude _id
    this.values = data?.values?.map(v => {
      return { id: v.id, value: v.value };
    });
  }

  /**
   * Converts a time to the lowest half like 08:13 to 08:00, 08:35 to 08:30.
   * In the UI this leads to a more consistent experience as it takes the half hour below the click.
   * @param time "HH:MM"
   */
  setNearest30TimeString(time: string) {
    if (!time.includes(":")) {
      Vue.$log.warn(
        `The specified value "${time}" does not conform to the required format.  The format is "HH:mm" where HH is 00-23, mm is 00-59.`
      );

      return;
    }

    const [hours, minutes] = time.split(":");
    const m = Math.floor(+minutes / 30) * 30;

    const timeString = `${hours.padStart(2, "0")}:${m.toString().padStart(2, "0")}`;
    this.startTime = timeString;
  }

  /**
   * Returns a string which identifies the user either by their name
   * or by email
   */
  getUserIdentifierString() {
    if (this.customerInformation?.firstName && this.customerInformation?.lastName) {
      return `${this.customerInformation?.firstName} ${this.customerInformation?.lastName}`;
    } else if (this.customerInformation?.email) {
      return this.customerInformation?.email;
    } else {
      return "Anonymous";
    }
  }

  getUserIdentifierStringInitials() {
    if (this.customerInformation?.firstName && this.customerInformation?.lastName) {
      return `${this.customerInformation?.firstName?.[0]}${this.customerInformation?.lastName?.[0]}`;
    } else {
      // Anonymous
      return "AN";
    }
  }

  getUserFromCustomer() {
    const customer: MrfiktivShortUserViewModelGen = {
      firstName: this.customerInformation?.firstName || "",
      lastName: this.customerInformation?.lastName || "",
      userName: this.customerInformation?.email || "",
      id: "0"
    };

    return customer;
  }

  map(data: Partial<BookingBookingDetailViewModelGen>) {
    this.id = data?.id || "";
    this.serviceId = data?.serviceId;
    this.resourceId = data?.resourceId || "";
    this.partnerId = data?.partnerId || "";

    this.startDate = data?.startDate || "";
    this.endDate = data?.endDate || "";

    if (data?.start) this.startDate = formatYearsMonthDay(new Date(data.start));
    if (data?.start) this.startTime = formatHoursAndMinutes(new Date(data.start));
    if (data?.end) this.endTime = formatHoursAndMinutes(new Date(data.end));

    this.duration = data?.duration || 0;
    this.timestamp = new Timestamp(data?.timestamp);
    this.isDeleted = data?.isDeleted || false;
    this.deletedAt = data?.deletedAt;
    this.customerInformation = data?.customerInformation;
    this.bookingInformation = data?.bookingInformation || {
      notes: ""
    };
    this.attachments = (data?.attachments || []).map(b => new Image(b));
    this.attachmentIds = [];
    // Exclude _id
    this.values = data?.values?.map(v => {
      return { id: v.id, value: v.value };
    });
  }

  async fetch(silent?: boolean): Promise<this> {
    try {
      const booking = await bookingService.findOneByPartner(this.partnerId, this.id);
      this.map(booking);
      BookingDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);

      if (!silent) throw e;
    }
    return this;
  }

  async delete(userNotification = false): Promise<void> {
    try {
      const booking = await bookingService.removeOneByPartner({
        id: this.id,
        partnerId: this.partnerId,
        userNotification: userNotification
      });
      this.map(booking);
      BookingDataAccessLayer.delete(this);
    } catch (e) {
      handleError(e);
    }
  }

  async update(userNotification = false): Promise<this> {
    try {
      const booking = await bookingService.updateOneByPartner(
        {
          id: this.id,
          partnerId: this.partnerId,
          userNotification: userNotification
        },
        {
          start: this.start,
          end: this.end,
          bookingInformation: this.bookingInformation,
          attachments: this.attachmentIds,
          customerInformation: this.customerInformation,
          resourceId: this.resourceId,
          serviceId: this.serviceId,
          values: this.values
        }
      );
      this.map(booking);
      BookingDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async create(silent = true): Promise<this> {
    try {
      const booking = await bookingService.createOneByPartner(this.partnerId, this.resourceId, {
        end: this.end,
        start: this.start,
        bookingInformation: this.bookingInformation,
        attachments: this.attachmentIds,
        values: this.values
      });

      this.map(booking);
      BookingDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
      if (!silent) throw e;
    }

    return this;
  }
}

type IBooking = BookingBase;
const Booking = Filter.createForClass(BookingBase);

export { IBooking, Booking };
