import { ConfigModule } from "@/store/modules/config";
import { UserModule } from "@/store/modules/me-user.store";
import { abilitiesPlugin, Can } from "@casl/vue/";
import { BarChart, LineChart, PieChart } from "echarts/charts";
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  TooltipComponent,
  TransformComponent
} from "echarts/components";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import Vue from "vue";
import ECharts from "vue-echarts";
import VuePictureSwipe from "vue-picture-swipe";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueLogger from "vuejs-logger";
import { UIHelper } from "../lib/UIHelper";
import VueAppInsights from "../plugins/AppInsightsPlugin";
import i18n from "../plugins/I18nPlugin";
import vuetify from "../plugins/VuetifyPlugin";
import "../registerServiceWorker";
import router from "../router/portal.router";
import store from "../store/VuexPlugin";
import Portal from "./Portal.vue";
import VueRx from "vue-rx";
import VuejsClipper from "vuejs-clipper";

const isProduction = process.env.NODE_ENV === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};

// link for toast-notification doc: https://maronato.github.io/vue-toastification/
const toastOptions = {
  position: "bottom-right"
};

Vue.use(Toast, toastOptions);

Vue.use(VueLogger as any, options);

Vue.use(UIHelper);

use([
  CanvasRenderer,
  BarChart,
  PieChart,
  TooltipComponent,
  LegendComponent,
  LineChart,
  DatasetComponent,
  MarkLineComponent,
  MarkPointComponent,
  TransformComponent,
  GridComponent
]);

Vue.component("Can", Can);

Vue.component("vue-picture-swipe", VuePictureSwipe);

Vue.component("v-chart", ECharts);

Vue.config.productionTip = false;

vuetify.framework.theme.dark = ConfigModule.darkMode;

const url = ConfigModule.mrfiktivBaseUrl + "/config";
Vue.use(VueAppInsights, {
  baseName: "portal",
  url: url,
  id: ConfigModule.appInsightsKey,
  cookie: false, // disable by default
  router
});

Vue.use(abilitiesPlugin, UserModule.abilities);
Vue.use(VueRx);
Vue.use(VuejsClipper as any, {
  components: {
    clipperBasic: true,
    clipperPreview: false,
    clipperRange: false
  }
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(Portal)
}).$mount("#portal");
