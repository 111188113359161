









import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import {
  MrfiktivDocumentViewModelGen,
  MrfiktivReferenceGen,
  MrfiktivReportViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { DocumentModule } from "@/store/modules/document.store";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import { Component, Prop } from "vue-property-decorator";
import Tooltip from "../utility/tooltip.vue";
import SharedContentMixin from "./SharedContentMixin.vue";
import { ISignDocument } from "@/models/sign-document.entity";

@Component({
  components: { Tooltip }
})
export default class SharedContentCardRefTypes extends SharedContentMixin {
  @Prop()
  reference!: MrfiktivReferenceGen;

  get itemDetail() {
    if (this.reference.refType === this.BackendResourceEnum.DOCUMENT) {
      return DocumentModule.paginationList.find((d: ISignDocument) => d.id === this.reference.refId);
    }

    if (this.reference.refType === this.BackendResourceEnum.REPORT) {
      return ReportPaginationModule.paginationList.find(
        (d: MrfiktivReportViewModelGen) => d._id === this.reference.refId
      );
    }

    return this.reference.refId;
  }

  get itemShortText() {
    const detail = this.itemDetail;

    if (detail && this.reference.refType === this.BackendResourceEnum.DOCUMENT) {
      return (detail as MrfiktivDocumentViewModelGen).title;
    }

    if (detail && this.reference.refType === this.BackendResourceEnum.REPORT) {
      return (detail as MrfiktivReportViewModelGen).numberplate;
    }

    return "";
  }

  get itemText() {
    const detail = this.itemDetail;

    if (detail && this.reference.refType === this.BackendResourceEnum.DOCUMENT) {
      const documentDetail = detail as MrfiktivDocumentViewModelGen;
      const title = documentDetail.title + "<br>";
      const description = documentDetail.description ? documentDetail.description + "<br>" : "";
      const date = simpleDoubleDigitDate(documentDetail.timestamp.created);

      return `${title} ${description} ${date}`;
    }

    if (detail && this.reference.refType === this.BackendResourceEnum.REPORT) {
      const documentDetail = detail as MrfiktivReportViewModelGen;
      const numberplate = documentDetail.numberplate + "<br>";
      const customerName = documentDetail.customerName + "<br>";
      const date = simpleDoubleDigitDate(documentDetail.timestamps.created);

      return `${numberplate} ${customerName} ${date}`;
    }

    return "";
  }
}
