import { permissionGuard } from "@/auth/permissionAuthGuard";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import BookingCalendar from "@/views/booking/BookingCalendar.vue";
import BookingView from "@/views/booking/BookingView.vue";
import BookingTableView from "@/views/booking/BookingTableView.vue";
import BookingSettingView from "@/views/booking/BookingSettingView.vue";
import ResourceDetail from "@/views/booking/ResourceDetail.vue";
import ResourceTableView from "@/views/booking/ResourceTableView.vue";
import ServiceDetail from "@/views/booking/ServiceDetail.vue";
import ServiceTableView from "@/views/booking/ServiceTableView.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import { FeatureModule } from "./../store/modules/feature.store";
import BookingPortalDetailView from "@/views/booking/portal/BookingPortalDetailView.vue";

Vue.use(VueRouter);

const routes: ICustomRouteConfig[] = [
  {
    path: "/partner/:partnerId/resource/",
    name: "ResourceTable",
    component: ResourceTableView,
    permission: {
      resource: ResourceEnum.RESOURCE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.BOOKING,
      icon: "mdi-grain",
      isEnabled: FeatureModule.onlineBooking,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 50
    }
  },
  {
    path: "/partner/:partnerId/resource/:resourceId",
    name: "ResourceDetail",
    component: ResourceDetail,
    permission: {
      resource: ResourceEnum.RESOURCE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/service/",
    name: "ServiceTable",
    component: ServiceTableView,
    permission: {
      resource: ResourceEnum.SERVICE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.BOOKING,
      icon: "mdi-hand-heart-outline",
      isEnabled: FeatureModule.onlineBooking,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 50
    }
  },
  {
    path: "/partner/:partnerId/service/:serviceId",
    name: "ServiceDetail",
    component: ServiceDetail,
    permission: {
      resource: ResourceEnum.SERVICE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/booking",
    name: "BookingView",
    component: BookingView,
    permission: {
      resource: ResourceEnum.BOOKING,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.BOOKING,
      icon: "mdi-calendar",
      isEnabled: FeatureModule.onlineBooking,
      isSideBarNavigation: true,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 50
    }
  },
  {
    path: "/partner/:partnerId/booking/list",
    name: "BookingTableView",
    component: BookingTableView,
    permission: {
      resource: ResourceEnum.BOOKING,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.BOOKING,
      icon: "mdi-calendar",
      isEnabled: FeatureModule.onlineBooking,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },

  {
    path: "/partner/:partnerId/booking/calendar",
    name: "BookingCalendar",
    component: BookingCalendar,
    permission: {
      resource: ResourceEnum.BOOKING,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.BOOKING,
      icon: "mdi-calendar-month-outline",
      isEnabled: FeatureModule.onlineBooking,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 50
    }
  },
  {
    path: "/partner/:partnerId/booking/setting",
    name: "BookingSettingView",
    component: BookingSettingView,
    permission: {
      resource: ResourceEnum.BOOKING,
      action: ActionEnum.MANAGE,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.BOOKING,
      icon: "mdi-cog",
      isEnabled: FeatureModule.onlineBooking,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false,
      priority: 50
    }
  },
  {
    path: "/partner/:partnerId/booking/:bookingId",
    name: "BookingPortalDetailView",
    component: BookingPortalDetailView,
    props: { isEdit: false },
    permission: {
      resource: ResourceEnum.BOOKING,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partner/:partnerId/booking/:bookingId/detail",
    name: "BookingPortalDetailViewEdit",
    component: BookingPortalDetailView,
    props: { isEdit: true },
    permission: {
      resource: ResourceEnum.BOOKING,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  }
];

const router = new VueRouter({
  routes,
  base: ""
});

router.beforeEach(permissionGuard);

export default router;

export const BookingRouter = routes;
