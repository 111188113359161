















import PartnerBookingCalendar from "@/components/booking/PartnerBookingCalendar.vue";
import ErrorCard from "@/components/utility/ErrorCard.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import { BookingModule } from "@/store/modules/booking.store";
import { handleError } from "@/lib/utility/handleError";
import Debug from "@/components/utility/Debug.vue";
import { ServiceModule } from "@/store/modules/service.store";
import { ResourceModule } from "@/store/modules/resource.store";
import { IBooking } from "@/models/booking.entity";

@Component({
  components: {
    TheLayoutPortal,
    PartnerBookingCalendar,
    Debug,
    ErrorCard
  }
})
export default class BookingCalendar extends Vue {
  isLoading = false;

  get partnerId() {
    return this.$route.params.partnerId || PartnerModule.partner.id;
  }

  get bookings(): IBooking[] {
    return BookingModule.filteredAndSorted;
  }

  get services() {
    return ServiceModule.maps.id;
  }

  get resources() {
    return ResourceModule.maps.id;
  }

  async mounted() {
    this.isLoading = true;
    try {
      await Promise.all([
        BookingModule.fetchAll({ partnerId: this.partnerId }),
        ServiceModule.fetchAll({ partnerId: this.partnerId }),
        ResourceModule.fetchAll({ partnerId: this.partnerId })
      ]);
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
    }
  }
}
