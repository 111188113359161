




















































































import Debug from "@/components/utility/Debug.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Blueprint, IBlueprint } from "@/models/blueprint.entity";
import { IVehicle } from "@/models/vehicle.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import DropAreaMulti from "../files/DropAreaMulti.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ImageDialog from "../utility/ImageDialog.vue";
import { MrfiktivCreateActivityLogDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ActivityLogModule, ActivityTypeEnum } from "@/store/modules/activity-log.store";
import { BlueprintTypeEnum } from "@/lib/enum/BlueprintType.enum";

@Component({
  components: {
    ConfirmActionDialog,
    DropAreaMulti,
    ImageDialog,
    Debug
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class BlueprintCreateDialog extends mixins(PartnerFallbackMixin) {
  @Prop()
  partnerId!: string;

  @Prop()
  vehicle!: IVehicle;

  @Prop()
  isDialogActive!: boolean;

  @Prop()
  hideButton!: boolean;

  @Prop({ default: false })
  fullscreen?: boolean;

  isLoading = false;
  loadingProgress = 0;
  files: File[] = [];

  valid = false;

  blueprints: IBlueprint[] = [];

  get blueprintTypeItems() {
    return [
      {
        key: BlueprintTypeEnum.INSIDE,
        text: this.$t("blueprint.types." + BlueprintTypeEnum.INSIDE)
      },
      {
        key: BlueprintTypeEnum.OUTSIDE,
        text: this.$t("blueprint.types." + BlueprintTypeEnum.OUTSIDE)
      },
      {
        key: BlueprintTypeEnum.OTHER,
        text: this.$t("blueprint.types." + BlueprintTypeEnum.OTHER)
      }
    ];
  }

  onLeftClick() {
    this.blueprints = [];
    this.files = [];
    this.isDialogActiveLocal = false;
  }

  show() {
    this.isDialogActiveLocal = true;
  }

  removeItem(index: number) {
    this.blueprints.splice(index, 1);
    this.files.splice(index, 1);
  }

  async onRightClick() {
    const step = 1 / this.blueprints.length;
    this.isLoading = true;
    try {
      for (const blueprint of this.blueprints) {
        const createdBlueprint = await blueprint.create();
        await this.createActivities(createdBlueprint);
        this.loadingProgress = this.loadingProgress + step;
      }
      this.$toast.info(this.$t("blueprint.create.success"));
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("blueprint.create.createError"));
    } finally {
      this.isLoading = false;
      this.isDialogActiveLocal = false;
      this.blueprints = [];
      this.files = [];
    }
  }

  createDto() {
    this.blueprints = [];
    for (const file of this.files) {
      const blueprint = new Blueprint(this.partnerId, this.vehicle.id, {});
      blueprint.file = file;
      blueprint.name = file.name;
      this.blueprints.push(blueprint);
    }
  }

  async createActivities(blueprint: IBlueprint) {
    try {
      const data: MrfiktivCreateActivityLogDtoGen = {
        source: {
          refType: BackendResourceEnum.VEHICLE,
          refId: this.vehicle.id
        },
        target: [
          {
            refType: BackendResourceEnum.BLUEPRINT,
            refId: blueprint.id
          }
        ],
        actionType: ActionEnum.CREATE,
        activity: ActivityTypeEnum.CREATE_BLUEPRINT,
        comment: blueprint.name
      };
      const activity = await ActivityLogModule.create({ partnerId: this.vehicle.partnerId, data: data });

      this.$emit("createdActivity", activity);
    } catch (error) {
      this.$log.error(error);
    }
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get isDialogActiveLocal() {
    return this.isDialogActive;
  }

  set isDialogActiveLocal(isDialogActive: boolean) {
    this.$emit("update:isDialogActive", isDialogActive);
  }
}
