
















































import PartnerReportInitializeDialog from "@/components/partner/PartnerReportInitializeDialog.vue";
import { ExportReportMetaTypeMap } from "@/lib/enum/export-report-meta-type.enum";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";
import { simpleDate } from "@/lib/utility/date-helper";
import { getDateFilterList } from "@/lib/utility/filter";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PaginationListMixin from "@/mixins/PaginationListMixin.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IReport } from "@/models/report.entity";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { reportStatusIconMap } from "@/store/mapper/report-icon.map";
import { ExportModule } from "@/store/modules/export.store";
import { FeatureModule } from "@/store/modules/feature.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import ProjectInitializeListsMixin from "@/views/project/mixins/ProjectInitializeListsMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import TheLayoutPortalList from "../../layouts/TheLayoutPortalList.vue";
import AssigneePreview from "../utility/AssigneePreview.vue";
import Tooltip from "../utility/tooltip.vue";

@Component({
  components: {
    PartnerReportInitializeDialog,
    Tooltip,
    TheLayoutPortalList,
    AssigneePreview
  }
})
export default class PartnerReportListPaginated extends mixins(
  DarkModeHighlightMixin,
  PaginationListMixin,
  PartnerFallbackMixin,
  ProjectInitializeListsMixin,
  PermissionMixin
) {
  isLoadingAll = false;

  statusFilter: IFilterListElement[] = [];

  dateFilter = getDateFilterList("timestamps");

  ProgressStatusEnum = ProgressStatusEnum;

  get filterList(): IFilterListElement[] {
    return [...this.dateFilter, ...this.statusFilter];
  }

  get paginationList() {
    return this.getModule().paginationList;
  }

  getModule() {
    return ReportPaginationModule;
  }

  async beforeMount() {
    if (FeatureModule.isUpdateProgressStatusEnabled) {
      const progressFilter = {
        label: String(this.$t("components.partner.PartnerReportList.progressStatus")),
        value: [],
        items: [ProgressStatusEnum.FINISHED, ProgressStatusEnum.IN_PROGRESS, ProgressStatusEnum.NEW],
        multiple: true,
        conditionCheck: (obj: IReport, condition: string) => {
          return obj.progressStatus === condition;
        }
      };
      this.statusFilter.push(progressFilter);
    }
  }

  async mounted() {
    ReportPaginationModule.setItemsPerPage(25);
    ReportPaginationModule.setSearch("");
    ReportPaginationModule.setFilters([]);

    await this.trySetByRouteOrDefault();
    await this.getPartnerUsers();

    if (!PartnerModule.partner._id) {
      throw new Error("No Partner Set");
    }

    //load from beginning
    await this.refresh();
  }

  async set(item: IReport) {
    this.$emit("set", item);
  }

  async refresh() {
    this.isLoadingAll = true;

    try {
      await ReportPaginationModule.fetchAll({
        partnerId: PartnerModule.partner._id
      });
    } catch (error) {
      this.$log.error("Error fetching reports");
      this.$toast.error(this.$t("components.partner.PartnerReportList.error"));
    } finally {
      this.isLoadingAll = false;
    }

    try {
      await ExportModule.checkSetups();
    } catch (error) {
      this.$log.error(error);
    }

    try {
      await ExportModule.getExportReportMetasByPartnerId(PartnerModule.partner._id);
    } catch (error) {
      this.$log.error(error);
    }
  }

  async loadAllFromLastId() {
    if (this.paginationList.length === 0) {
      await ReportPaginationModule.fetchAll({
        partnerId: PartnerModule.partner._id
      });
    } else {
      await ReportPaginationModule.fetchRemaining({ partnerId: PartnerModule.partner._id });
    }
  }

  async loadMore() {
    try {
      this.isLoadingMore = true;
      await this.loadAllFromLastId();
    } catch (e) {
      this.$toast.error((e as any).message);
    } finally {
      this.isLoadingMore = false;
    }
  }

  setIsLoadingDetail(value: boolean) {
    this.$emit("isLoadingDetail", value);
  }

  /**
   * Some methods for the presentation of items in the list
   */
  itemFunctions = {
    isUpdateProgressStatusEnabled() {
      return FeatureModule.isUpdateProgressStatusEnabled;
    },
    getProgressIcon: (item: IReport) => {
      if (!this.itemFunctions.isUpdateProgressStatusEnabled()) return "";
      return reportStatusIconMap.get(item.progressStatus);
    },
    getProgressStatus: (item: IReport) => {
      return this.$t("partner.PartnerReportDetailChangeProgress." + item.progressStatus);
    },
    getExportReportMetaIcon: (item: IReport) => {
      const found = ExportModule.exportReportMetas.get(item._id);

      return found ? "mdi-file-send" : "";
    },
    getExportReportMetaType: (item: IReport) => {
      const found = ExportModule.exportReportMetas.get(item._id);

      return found && found.length >= 1
        ? found.map(f => ExportReportMetaTypeMap.get(f.exportReportMetaType)).join(", ")
        : "";
    },
    isActive: (item: IReport) => {
      if (!item) return "";
      return item._id === PartnerModule.report._id ? "v-item--active v-list-item--active" : "";
    },
    date: (item: IReport) => {
      return simpleDate(item.timestamps?.created);
    }
  };
}
