














import CostTable from "@/components/cost/CostTable.vue";
import Debug from "@/components/utility/Debug.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CostModule } from "@/store/modules/cost.store";
import { mixins } from "vue-class-component";
import { Component, Ref } from "vue-property-decorator";
import CostMixin from "./CostMixin.vue";
import { CustomFieldModule } from "@/store/modules/custom-field.store";
import { handleError } from "@/lib/utility/handleError";
import { ICost, Cost } from "@/models/cost.entity";
import { $t } from "@/lib/utility/t";
import { CostGoToHelper } from "@/lib/utility/cost.go-to-helper";
import { CostTypeEnum } from "@/lib/enum/cost-type.enum";

@Component({
  components: {
    CostTable,
    Debug,
    TheLayoutPortal,
    MHeader
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CostTableView extends mixins(PartnerFallbackMixin, CostMixin) {
  @Ref("costTable")
  costTable!: CostTable;

  readonly AMOUNT_COST = "amountCost";

  /**
   * refs that will be set in new costs and filtered (via or) for in table
   */
  refs: MrfiktivReferenceGen[] = [];

  selectedItems: ICost[] = [];

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get breadCrumbList(): IBreadcrumb[] {
    const crumbs: IBreadcrumb[] = [];

    crumbs.push(this.breadCrumbs.CostTable(this.partnerId));

    return crumbs;
  }

  get chips(): IAction[] {
    const chips: IAction[] = [];

    chips.push({
      text: `${CostModule.filtered.length} ${this.$t("cost.costs")}`,
      key: this.AMOUNT_COST,
      disabled: true
    });

    const totalTotal = this.totalTotal;
    chips.push({
      text: `${$t("total")}: ${totalTotal.toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR"
      })}`,
      key: "selectedTotal",
      disabled: true,
      color: totalTotal < 0 ? "error" : totalTotal > 0 ? "success" : ""
    });

    if (this.selectedItems.length > 0) {
      const selectedTotal = this.selectedTotal;
      chips.push({
        text: `${$t("selected")}: ${selectedTotal.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR"
        })}`,
        key: "selectedTotal",
        disabled: true,
        color: selectedTotal < 0 ? "error" : selectedTotal > 0 ? "success" : ""
      });
    }

    return chips;
  }

  get selectedTotal() {
    let total = 0;

    for (const item of this.selectedItems) {
      if (item.expenseOrIncome === CostTypeEnum.EXPENSE) total -= item.absoluteTotal;
      else total += item.absoluteTotal;
    }

    return total;
  }

  get totalTotal() {
    let total = 0;

    const viewCosts = CostModule.filtered;
    for (const item of viewCosts) {
      if (item.expenseOrIncome === CostTypeEnum.EXPENSE) total -= item.absoluteTotal;
      else total += item.absoluteTotal;
    }

    return total;
  }

  mounted() {
    this.trySetByRouteOrDefault();

    CustomFieldModule.fetchAllFromBeginning({ partnerId: this.partnerId }).catch(handleError);

    CostModule.setFilters([]);

    const costId = this.$route.query.costId as string;
    if (costId) {
      const cost = new Cost({ partnerId: this.partnerId, id: costId });
      this.costTable?.setSelectedItem(cost);
    }
  }

  openSideCard(cost: ICost) {
    new CostGoToHelper(this.$router).setUrl(
      CostGoToHelper.locations.costTable({
        partnerId: this.partnerId,
        query: cost ? { costId: cost.id } : {}
      })
    );
  }
}
