
import { GoToHelper } from "@/lib/utility/goToHelper";
import { MrfiktivBaseCreateSharedContentDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class SharedContentMixin extends Vue {
  getIcon(type: BackendResourceEnum) {
    return (
      new Map([
        [BackendResourceEnum.REPORT, "mdi-car"],
        [BackendResourceEnum.DOCUMENT, "mdi-file-document"]
      ]).get(type) ?? "mdi-folder-question-outline"
    );
  }

  get BackendResourceEnum() {
    return BackendResourceEnum;
  }

  get resourceList() {
    return [BackendResourceEnum.REPORT, BackendResourceEnum.DOCUMENT];
  }

  get partnerId() {
    return this.$route.params.partnerId ?? PartnerModule.partner._id;
  }

  goToDetail(content: MrfiktivBaseCreateSharedContentDtoGen) {
    const goToHelper = new GoToHelper(this.$router);
    if (content.refType === BackendResourceEnum.REPORT) {
      goToHelper.goToReportDetail(content.refId, this.partnerId, true);
    } else if (content.refType === BackendResourceEnum.DOCUMENT) {
      goToHelper.goToDocumentDetail(content.refId, this.partnerId, true);
    }
  }
}
