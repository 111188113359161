import { UpdateAvailabilityDto } from "@/lib/dto/update-availability.dto";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { getRandomColor } from "@/lib/utility/booking/colorUtils";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { msToHoursAndMinutes, timeToMs } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import serviceService from "@/services/booking/services/serviceService";
import {
  BookingAvailabilityGen,
  BookingCustomFieldListElementDtoGen,
  BookingCustomerFieldConfigurationDtoGen,
  BookingCustomerFieldConfigurationOptionsDtoGen,
  BookingImageConfigurationDtoGen,
  BookingImageConfigurationOptionsDtoGen,
  BookingServiceViewModelGen
} from "@/services/booking/v1/data-contracts";
import { ServiceDataAccessLayer } from "@/store/modules/access-layers/service.access-layer";
import { ReportImageType } from "./Report/ReportImageType";
import { AddressWithGeo, IAddressWithGeo } from "./address.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { CustomFieldListElement, ICustomFieldListElement } from "./custom-field-list-element.entity";
class AvailabilityBase implements BookingAvailabilityGen {
  isAvailable: boolean;
  startTime: string;
  endTime: string;

  constructor(data?: BookingAvailabilityGen) {
    this.isAvailable = data?.isAvailable || false;
    this.startTime = data?.startTime || "";
    this.endTime = data?.endTime || "";
  }
}

export type IAvailability = AvailabilityBase;
export const Availability = AvailabilityBase;

class CustomerFieldConfigurationOptionsBase implements BookingCustomerFieldConfigurationOptionsDtoGen {
  isActive: boolean;
  isRequired: boolean;

  constructor(data?: BookingCustomerFieldConfigurationOptionsDtoGen) {
    this.isActive = data?.isActive ?? false;
    this.isRequired = data?.isRequired ?? false;
  }
}

export type ICustomerFieldConfigurationOptions = CustomerFieldConfigurationOptionsBase;
export const CustomerFieldConfigurationOptions = CustomerFieldConfigurationOptionsBase;

class CustomerFieldConfigurationDtoBase implements BookingCustomerFieldConfigurationDtoGen {
  field: string;
  configuration: ICustomerFieldConfigurationOptions;

  constructor(data?: BookingCustomerFieldConfigurationDtoGen) {
    this.field = data?.field ?? "";
    this.configuration = new CustomerFieldConfigurationOptions(data?.configuration);
  }
}

export type ICustomerFieldConfigurationDto = CustomerFieldConfigurationDtoBase;
export const CustomerFieldConfigurationDto = CustomerFieldConfigurationDtoBase;

class ImageConfigurationOptionsBase implements BookingImageConfigurationOptionsDtoGen {
  isActive: boolean;
  isRequired: boolean;
  isMultiple: boolean;

  constructor(data?: Partial<BookingImageConfigurationOptionsDtoGen>) {
    this.isActive = data?.isActive ?? false;
    this.isRequired = data?.isRequired ?? false;
    this.isMultiple = data?.isMultiple ?? false;
  }
}

export type IImageConfigurationOptions = ImageConfigurationOptionsBase;
export const ImageConfigurationOptions = ImageConfigurationOptionsBase;

class ImageConfigurationBase implements BookingImageConfigurationDtoGen {
  type: ReportImageType;
  configuration: IImageConfigurationOptions;

  constructor(data?: Partial<BookingImageConfigurationDtoGen>) {
    this.type = (data?.type ?? "") as ReportImageType;
    this.configuration = new ImageConfigurationOptions(data?.configuration);
  }
}

export type IImageConfiguration = ImageConfigurationBase;
export const ImageConfiguration = ImageConfigurationBase;

@IsFilterable
class ServiceBase implements IEntity<IService>, BookingServiceViewModelGen {
  customFieldConfig: ICustomFieldListElement[];
  id: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.service.id"
  })
  get _id() {
    return this.id;
  }

  public static readonly SERVICE_INTERNAL_NAME = "BLOCKER";
  public static readonly SERVICE_INTERNAL_COLOR = "grey";
  public static readonly SERVICE_DEFAULT_COLOR = "primary";

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.service.name"
  })
  name = "";

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.service.description"
  })
  description = "";

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.service.slug"
  })
  slug = "";

  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.service.isActive"
  })
  isActive = false;

  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.service.duration"
  })
  duration = 0;

  timezone = "Europe/Berlin";
  availability: IAvailability[] = this.defaultAvailability;

  resourceIds: string[] = [];
  color = "";
  icon = "";
  imageUrl = "";
  /**
   * The service cost
   * @example 15
   */
  cost = 0;

  /** The service address */
  address: IAddressWithGeo = {
    street: "",
    state: "",
    city: "",
    zip: "",
    countryCode: CountryCodeEnum.germany,
    geo: {
      lat: 0,
      lng: 0
    }
  };

  /**
   * Default configuration
   */
  customerFieldConfig: ICustomerFieldConfigurationDto[] = [
    {
      field: "firstName",
      configuration: {
        isActive: true,
        isRequired: false
      }
    },
    {
      field: "lastName",
      configuration: {
        isActive: true,
        isRequired: true
      }
    },
    {
      field: "phone",
      configuration: {
        isActive: true,
        isRequired: false
      }
    },
    {
      field: "numberPlate",
      configuration: {
        isActive: true,
        isRequired: false
      }
    }
  ].map(a => new CustomerFieldConfigurationDto(a));

  imageConfig: BookingImageConfigurationDtoGen[] = [];

  /** Internal state to show duration in seconds in readable format hh:mm */
  private _durationInSecondsString = "";

  partnerId: string;

  timestamp: ITimestamp;

  set durationInSecondsString(newDuration: string) {
    this._durationInSecondsString = newDuration;
    this.duration = timeToMs(newDuration);
  }

  get durationInSecondsString() {
    return this._durationInSecondsString;
  }

  constructor(service?: Partial<BookingServiceViewModelGen>) {
    this.id = service?.id ?? "";
    this.partnerId = service?.partnerId ?? "";

    this.name = service?.name ?? "";
    this.description = service?.description ?? "";
    this.slug = service?.slug ?? "";
    this.isActive = service?.isActive ?? false;

    this.resourceIds = service?.resourceIds ?? [];
    this.color = service?.color ?? getRandomColor();
    this.icon = service?.icon ?? "";
    this.imageUrl = service?.imageUrl ?? "";
    this.cost = service?.cost ?? 0;

    this.timestamp = new Timestamp(service?.timestamp);
    this.customFieldConfig = service?.customFieldConfig?.map(c => new CustomFieldListElement(c)) ?? [];

    if (service) {
      this.address = new AddressWithGeo(service?.address);

      if (service.customerFieldConfig && service.customerFieldConfig.length > 0) {
        this.customerFieldConfig = service.customerFieldConfig;
      }

      if (service.imageConfig && service.imageConfig.length > 0) {
        this.imageConfig = service.imageConfig.map(a => new ImageConfiguration(a));
      }

      if (service.availability && service.availability.length == 7) {
        this.availability = service.availability.map(a => new UpdateAvailabilityDto(a).toDto());
      }

      if (service.timezone) {
        this.timezone = service.timezone;
      }

      this.duration = service.duration ?? 0;
      this._durationInSecondsString = msToHoursAndMinutes(this.duration);
    } else {
      this._durationInSecondsString = msToHoursAndMinutes(1800);
    }
  }

  map(service: Partial<BookingServiceViewModelGen>) {
    this.id = service.id ?? "";
    this.name = service.name ?? "";
    this.description = service.description ?? "";
    this.slug = service.slug ?? "";
    this.isActive = service.isActive ?? false;
    this.duration = service.duration ?? 0;
    this.resourceIds = service.resourceIds ?? [];
    this.color = service.color ?? getRandomColor();
    this.icon = service.icon ?? "";
    this.imageUrl = service.imageUrl ?? "";
    this.cost = service.cost ?? 0;
    this.address = new AddressWithGeo(service?.address);
    this.timestamp = new Timestamp(service.timestamp);

    if (service.customerFieldConfig && service.customerFieldConfig.length > 0) {
      this.customerFieldConfig = service.customerFieldConfig;
    }

    if (service.imageConfig && service.imageConfig.length > 0) {
      this.imageConfig = service.imageConfig.map(a => new ImageConfiguration(a));
    }

    if (service.customFieldConfig && service.customFieldConfig.length > 0) {
      this.customFieldConfig = service.customFieldConfig.map(c => new CustomFieldListElement(c));
    }

    if (service.availability && service.availability.length == 7) {
      this.availability = service.availability.map(a => new UpdateAvailabilityDto(a).toDto());
    }

    if (service.timezone) {
      this.timezone = service.timezone;
    }

    this._durationInSecondsString = msToHoursAndMinutes(this.duration);
  }

  async fetch(): Promise<this> {
    try {
      const service = await serviceService.findOneByPartnerId(this.partnerId, this.id);

      this.map(service);

      ServiceDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async replaceOneByPartnerId(): Promise<this> {
    try {
      const service = await serviceService.replaceOneByPartnerId(this.partnerId, this.id, {
        address: this.address,
        availability: this.availability,
        color: this.color,
        cost: this.cost,
        customerFieldConfig: this.customerFieldConfig,
        description: this.description,
        duration: this.duration,
        icon: this.icon,
        imageConfig: this.imageConfig,
        imageUrl: this.imageUrl,
        isActive: this.isActive,
        name: this.name,
        resourceIds: this.resourceIds,
        slug: this.slug,
        timezone: this.timezone,
        customFieldConfig: this.customFieldConfigDto
      });

      this.map(service);

      ServiceDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async delete(): Promise<void> {
    try {
      const service = await serviceService.removeOneByPartnerId(this.partnerId, this.id);

      this.map(service);

      ServiceDataAccessLayer.delete(this);
    } catch (e) {
      handleError(e);
    }
  }

  private get customFieldConfigDto(): BookingCustomFieldListElementDtoGen[] {
    return this.customFieldConfig.map(c => {
      return {
        configuration: c.configuration,
        customField: c.customField.id
      };
    });
  }

  private get defaultAvailability(): AvailabilityBase[] {
    return [
      ...Array(7)
        .fill(0)
        // eslint-disable-next-line
        .map(x => new UpdateAvailabilityDto().toDto())
    ];
  }

  async create(): Promise<this> {
    try {
      const service = await serviceService.create(this.partnerId, {
        availability: this.availability,
        customerFieldConfig: this.customerFieldConfig,
        duration: this.duration,
        isActive: this.isActive,
        name: this.name,
        resourceIds: this.resourceIds,
        slug: this.slug,
        timezone: this.timezone,
        address: this.address,
        color: this.color,
        cost: Number(this.cost),
        description: this.description,
        icon: this.icon,
        imageConfig: this.imageConfig,
        imageUrl: this.imageUrl,
        customFieldConfig: this.customFieldConfigDto
      });

      this.map(service);

      ServiceDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  isUpdateable?: boolean | undefined;
}

type IService = ServiceBase;
const Service = Filter.createForClass(ServiceBase);

export { IService, Service };
