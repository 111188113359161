








































import FleetVehicleCrudMixin from "@/components/fleet/FleetVehicleCrudMixin.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import MActionList from "@/components/utility/mmmint/MActionList.vue";
import MDetailForm, { MDetailFormConfigFactory } from "@/components/utility/mmmint/MDetailForm.vue";
import MDetailTable from "@/components/utility/mmmint/MDetailTable.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { TransmissionTypeEnum } from "@/lib/enum/transmission.enum";
import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { IMDetailFormConfig, MDetailFormConfig } from "@/lib/formable";
import { propertiesToArray, setNestedObjectValues } from "@/lib/objectPath-helper";
import { requiredRule } from "@/lib/rules/requiredRule";
import { driveTypeMap } from "@/lib/utility/driveTypeMap";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { MrfiktivVehicleRegistrationViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgRegistration } from "@/store/models/thg/registration";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { VehicleDetailActions } from "./FleetVehicleDetailView.vue";
import { VehicleTypes } from "@/store/enum/vehicle-types.enum";
import { Vehicle, IVehicle } from "@/models/vehicle.entity";

export enum VEHICLE_KEYS {
  DISPLAYNAME = "displayName"
}

/**
 * Used for displaying and ordering the form fields.
 */
const registration: Omit<
  Omit<Required<MrfiktivVehicleRegistrationViewModelGen>, "identificationnumber">,
  "numberplate"
> = {
  /**
   * @inheritdoc
   */
  name: "",

  /**
   * @inheritdoc
   */
  firstname: "",

  /**
   * @inheritdoc
   */
  street: "",

  /**
   * @inheritdoc
   */
  zipCode: "",

  /**
   * @inheritdoc
   */
  city: "",

  /**
   * @inheritdoc
   */
  country: "",

  /**
   * @inheritdoc
   */
  huMonth: "",

  /**
   * @inheritdoc
   */
  huYear: "",

  /**
   * @inheritdoc
   */
  firstregistrationDay: "",

  /**
   * @inheritdoc
   */
  firstregistrationMonth: "",

  /**
   * @inheritdoc
   */
  firstregistrationYear: "",

  /**
   * @inheritdoc
   */
  initialRegistrationDay: "",

  /**
   * @inheritdoc
   */
  initialRegistrationMonth: "",

  /**
   * @inheritdoc
   */
  initialRegistrationYear: "",

  /**
   * @inheritdoc
   */
  manufacturerNameCode: "",

  /**
   * @inheritdoc
   */
  manufacturerTypeCode: "",

  /**
   * @inheritdoc
   */
  vehicleClass: "",

  /**
   * @inheritdoc
   */
  manufacturerType: "",

  /**
   * @inheritdoc
   */
  manufacturerDescription: "",

  /**
   * @inheritdoc
   */
  manufacturerName: "",

  /**
   * @inheritdoc
   */
  driveTyp: "",

  /**
   * @inheritdoc
   */
  transmission: ""
};

/**
 * Used for displaying and ordering the form fields.
 */
const vehicle: Omit<
  Required<IVehicle>,
  | "id"
  | "_id"
  | "partnerId"
  | "timestamp"
  | "contracts"
  | "mileages"
  | "drivers"
  | "documents"
  | "registrationDate"
  | "commissioningDate"
  | "currentDriver"
  | "tags"
  | "fetch"
  | "map"
  | "loading"
  | "openDetail"
  | "blueprints"
> = {
  displayName: "",
  numberplate: "",
  identificationnumber: "",
  commissioningDateString: "",
  state: VehicleStateEnum.UPCOMING,
  available: false,
  registration: registration,
  note: ""
};

@Component({
  components: {
    TheLayoutPortal,
    MHeader: MHeader,
    MActionList,
    MDetailViewGrid,
    MDetailTable,
    MDetailForm,
    ConfirmActionDialog
  }
})
export default class VehicleDetailView extends mixins(FleetVehicleCrudMixin, PartnerFallbackMixin) {
  loading = false;
  isFormShown = true;
  DETAIL = "detail";

  isDeleteDialogActive = false;

  get keys() {
    return propertiesToArray(vehicle);
  }

  get vehicleKeys() {
    return Object.keys(vehicle);
  }

  get vehicleEnumValues() {
    return Object.values(VEHICLE_KEYS);
  }

  get vehicleId() {
    return this.$route.params.vehicleId;
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  get vehicle() {
    if (!this.vehicleAggregation?.vehicle) {
      return undefined;
    }

    return new Vehicle(this.vehicleAggregation.vehicle);
  }

  get chips(): IAction[] {
    return [];
  }

  get actions(): IAction[] {
    return [
      {
        text: $t("project.ticket.actions.toOverview"),
        key: this.DETAIL,
        icon: "mdi-open-in-new"
      },
      {
        text: $t("views.fleet.actions.deleteVehicle"),
        key: VehicleDetailActions.DELETE,
        icon: "mdi-delete-outline",
        color: "red"
      }
    ];
  }

  get breadCrumbs() {
    return [
      {
        text: $t("common.nouns.fleet"),
        exact: true,
        disabled: false,
        to: {
          name: "FleetHome",
          params: {
            partnerId: this.partnerId
          }
        }
      },
      {
        text: this.vehicle?.displayName,
        exact: true,
        disabled: false,
        to: {
          name: "FleetVehicleDetail",
          params: {
            partnerId: this.partnerId,
            vehicleId: this.vehicleId
          }
        }
      },
      {
        text: "Details",
        exact: true,
        disabled: true,
        to: {
          name: "VehicleDetailView",
          params: {
            partnerId: this.partnerId,
            vehicleId: this.vehicleId
          }
        }
      }
    ];
  }

  get config(): IMDetailFormConfig[] {
    if (!this.vehicle) {
      return [];
    }

    const baseConfigFactory = new MDetailFormConfigFactory(this.vehicle, this.keys, "objects.vehicle");

    const DRIVE_TYPE_SELECTION = Array.from(driveTypeMap.entries()).map(entry => {
      return {
        text: `${entry[1].text} (${entry[0]})`,
        value: entry[0]
      };
    });

    const VEHICLE_STATE_SELECTION = Object.values(VehicleStateEnum).map(s => {
      return {
        text: $t(`views.fleet.FleetVehicleListView.state.${s}`),
        value: s
      };
    });

    const AVAILABILITY_SELECTION = [
      { text: $t("yes"), value: true },
      { text: $t("no"), value: false }
    ];

    const VEHICLE_CLASS_SELECTION = Object.values(VehicleTypes).map(t => {
      return {
        text: `${$t(`views.fleet.FleetVehicleListView.type.${t}.title`)} ${$t(
          `views.fleet.FleetVehicleListView.type.${t}.classSpeaking`
        )} ${$t(`views.fleet.FleetVehicleListView.type.${t}.hint`)}`,
        value: t
      };
    });

    const TRANSMISSION_SELECTION = [
      { text: $t("automatic"), value: TransmissionTypeEnum.AUTOMATIC },
      { text: $t("manual"), value: TransmissionTypeEnum.MANUAL }
    ];

    const REGISTRATION_KEYS: string[] = this.keys.filter(k => k.includes("registration"));

    const TEXT_AREAS = ["note"];

    const DATES = ["commissioningDateString"];

    const AUTO_COMPLETES = new Map<
      string,
      | boolean[]
      | string[]
      | {
          text: string;
          value: boolean | string;
        }[]
    >([
      ["state", VEHICLE_STATE_SELECTION],
      ["available", AVAILABILITY_SELECTION],
      ["registration.driveTyp", DRIVE_TYPE_SELECTION],
      ["registration.country", Object.values(CountryCodeEnum)],
      ["registration.firstregistrationDay", ThgRegistration.DAY],
      ["registration.firstregistrationMonth", ThgRegistration.MONTH],
      ["registration.firstregistrationYear", ThgRegistration.YEAR],
      ["registration.initialRegistrationDay", ThgRegistration.DAY],
      ["registration.initialRegistrationMonth", ThgRegistration.MONTH],
      ["registration.initialRegistrationYear", ThgRegistration.YEAR],
      ["registration.huMonth", ThgRegistration.MONTH],
      ["registration.huYear", ThgRegistration.YEAR],
      ["registration.vehicleClass", VEHICLE_CLASS_SELECTION],
      ["registration.transmission", TRANSMISSION_SELECTION]
    ]);

    for (const key of REGISTRATION_KEYS) {
      baseConfigFactory.setCategory(key, "common.nouns.vehicleRegistration");
    }

    for (const key of TEXT_AREAS) {
      baseConfigFactory.setType(key, DetailFormComponentsEnum.TEXT_AREA);
    }

    for (const key of DATES) {
      baseConfigFactory.addProps(key, { type: "date" });
    }

    for (const key of AUTO_COMPLETES.keys()) {
      const values = AUTO_COMPLETES.get(key);
      if (values?.length && (values[0] as any).value) {
        baseConfigFactory.setType(key, DetailFormComponentsEnum.AUTO_COMPLETE).addProps(key, { items: values });
      } else {
        baseConfigFactory.setType(key, DetailFormComponentsEnum.AUTO_COMPLETE).addProps(key, {
          items: values,
          "item-text": "text",
          "item-value": "value"
        });
      }
    }

    return baseConfigFactory.create();
  }

  get required() {
    return [requiredRule()];
  }

  async mounted() {
    this.loading = true;

    await this.trySetByRouteOrDefault();

    await this.loadVehicle();

    this.loading = false;
  }

  processAction(action: IAction) {
    switch (action.key) {
      case VehicleDetailActions.DELETE:
        this.isDeleteDialogActive = true;
        break;
      case this.DETAIL:
        new GoToHelper(this.$router).goToVehicleDetail(this.$route.params.vehicleId, this.partnerId);
        break;
    }
  }

  abortChanges() {
    const config = new MDetailFormConfig(this.config);
    for (const key of this.keys) {
      config.configValueByKey = { key: key, value: this.vehicle?.[key] };
    }
  }

  async syncChanges() {
    if (!this.vehicle) {
      return;
    }

    const config = new MDetailFormConfig(this.config);

    for (const key of this.keys) {
      setNestedObjectValues(this.vehicle, key, config.getConfigValueByKey(key));
    }

    if (this.vehicle.registration) {
      this.vehicle.registration.numberplate = this.vehicle.numberplate;
      this.vehicle.registration.identificationnumber = this.vehicle.identificationnumber ?? "";
    }

    await this.updateVehicle(this.vehicle);
  }

  confirmDeletion() {
    this.isDeleteDialogActive = false;
    this.deleteVehicle();
  }
}
