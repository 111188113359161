









import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IBooking } from "@/models/booking.entity";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { ServiceModule } from "@/store/modules/service.store";
import { ResourceModule } from "@/store/modules/resource.store";
import BookingTable from "@/components/booking/BookingTable.vue";
import { handleError } from "@/lib/utility/handleError";
import { BookingMDetailActionEnum, BookingGoToActionEmitData } from "@/components/booking/portal-detail-card/ui-types";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { CustomFieldModule } from "@/store/modules/custom-field.store";
import { $t } from "@/lib/utility/t";

@Component({
  components: {
    BookingTable,
    MHeader,
    TheLayoutPortal
  }
})
export default class BookingTableView extends mixins(PartnerFallbackMixin) {
  selectedItems: IBooking[] = [];

  get partnerId(): string {
    return this.$route.params.partnerId;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    actions.push({
      text: $t("views.booking.BookingView.goToBookingCalendar"),
      key: "bookingCalendar",
      exec: () => new GoToHelper(this.$router).goToBooking(this.partnerId, true)
    });

    return actions;
  }

  async processGoToAction(data: BookingGoToActionEmitData) {
    const action = data.action;

    switch (action) {
      case BookingMDetailActionEnum.GO_TO_EDIT:
        await new GoToHelper(this.$router).goToBookingEdit(this.partnerId, data.booking.id);
        break;
      case BookingMDetailActionEnum.GO_TO_RESOURCE:
        await new GoToHelper(this.$router).goToResourceDetail(this.partnerId, data.booking.resourceId, true);
        break;
      case BookingMDetailActionEnum.GO_TO_SERVICE:
        await new GoToHelper(this.$router).goToServiceDetail(this.partnerId, data.booking.serviceId || "", true);
        break;
      case BookingMDetailActionEnum.GO_TO_OVERVIEW:
      case BookingMDetailActionEnum.OPEN_IN_NEW:
        await new GoToHelper(this.$router).goToBookingDetail(this.partnerId, data.booking.id);
        break;
      default:
        throw new Error(`Unknown action: ${action}`);
    }
  }

  async mounted() {
    this.trySetByRouteOrDefault();

    CustomFieldModule.fetchAllFromBeginning({ partnerId: this.partnerId }).catch(handleError);

    try {
      await Promise.all([
        ServiceModule.fetchAll({ partnerId: this.partnerId }),
        ResourceModule.fetchAll({ partnerId: this.partnerId })
      ]);
    } catch (error) {
      handleError(error);
    }
  }
}
