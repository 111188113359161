import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IService, Service } from "@/models/service.entity";
import serviceService from "@/services/booking/services/serviceService";
import { BookingServiceControllerFindAllByPartnerIdParamsGen } from "@/services/booking/v1/data-contracts";
import { Module, getModule } from "vuex-module-decorators";
import store from "../../store/VuexPlugin";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ServiceDataAccessLayer } from "./access-layers/service.access-layer";
import { IPaginationParams } from "@/lib/utility/data/pagination-params.interface";
import { IPageFilterElement } from "@/models/page-filter-element.entity";

const ServicePageDataProvider = new (class extends AbstractPageDataProvider<
  IService,
  IPaginationParams & { partnerId: string }
> {
  itemsPerPage = 25;

  async getPage(query: IPaginationParams & { partnerId: string }): Promise<IPageViewModel<IService>> {
    const res = await serviceService.findAllByPartnerId(query);

    const meta = res.meta;
    const data = (res.data ?? []) as IService[];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "service",
  store
})
export class ServiceStore extends PaginatedBaseStore<IService, BookingServiceControllerFindAllByPartnerIdParamsGen> {
  protected _data = ServiceDataAccessLayer;
  protected _pageProvider = ServicePageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  hiddenFilter: IPageFilterElement[] = [];

  filterOptions = Service.filterables;

  get activeItems() {
    return this.entities.filter(e => e.isActive);
  }
}

export const ServiceModule = getModule(ServiceStore);
