









































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Tooltip from "../tooltip.vue";

/**
 * Defines position and color of a single marker
 */
export interface IMarker {
  /**
   * relative position measured from top
   */
  top: number;

  /**
   * relative position measured from left
   */
  left: number;

  /**
   * Optional color property for each marker
   */
  color?: string;

  /**
   * text of the marker
   */
  text?: string;
}

/**
 * adds the css style to the IMarker interface
 */
export interface IMarkerAbsolute extends IMarker {
  style: string;
}

@Component({
  components: { Tooltip }
})
export default class MImageMarker extends Vue {
  @Prop() src!: string;
  @Prop({ default: "medium" }) markerSize!: "x-small" | "small" | "medium" | "large";
  @Prop() markers!: IMarker[];
  @Prop({ default: false }) deactivated!: boolean; // New prop to control deactivation

  imageLoaded = false;
  showMagnifier = false;
  magnifierSize = 200;
  magnificationFactor = 4;
  magnifierPosition = { x: 0, y: 0, xOffset: -1 };
  magnifierTimeout: number | null = null; // Timeout to control delayed magnifier display

  mounted() {
    this.imageLoaded = false;
    window.addEventListener("resize", this.onResize);
  }

  /**
   * Calculate the marker positions after image is loaded
   * add a delay of 0.1 seconds
   */
  async onImageLoad() {
    await this.delay(100);
    this.recalculateMarkerPositions();
    this.imageLoaded = true;
  }

  /**
   * Calculate the marker positions if the image size changes
   */
  onResize() {
    this.imageLoaded = false;
    this.recalculateMarkerPositions();
    this.imageLoaded = true;
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    if (this.magnifierTimeout) {
      clearTimeout(this.magnifierTimeout);
    }
  }

  /**
   * Calculate the marker positions if the image size changes
   */
  recalculateMarkerPositions() {
    this.$forceUpdate();
  }

  /**
   * Watch for changes in the image source
   */
  @Watch("src")
  onSrcChange() {
    this.imageLoaded = false;
    this.$log.debug("Image changed");
  }

  /**
   * Calculate the markerDiameter based on size
   */
  get markerDiameter() {
    switch (this.markerSize) {
      case "x-small":
        return 10;
      case "small":
        return 15;
      case "medium":
        return 20;
      case "large":
        return 30;
      default:
        return 20;
    }
  }

  /**
   * Calculate the markerFontsize based on markerSize
   */
  get markerFontSize() {
    switch (this.markerSize) {
      case "small":
        return "xx-small";
      case "medium":
        return "xx-small";
      case "large":
        return "small";
      default:
        return "xx-small";
    }
  }

  /**
   * Hide text if markerSize is x-small
   */
  get showText() {
    return this.markerSize !== "x-small";
  }

  /**
   * Get the marker positions and calculate the marker style
   */
  get markerPositions() {
    const markerPositions: IMarkerAbsolute[] = [];
    if (!this.imageLoaded) {
      return [];
    }
    for (const marker of this.markers) {
      markerPositions.push({
        ...marker,
        style: this.calculateStyle(marker) || ""
      });
    }

    return markerPositions;
  }

  /**
   * get image class dynamicly
   */
  get imageClass() {
    return this.deactivated ? "image-deactivated" : "image-activated";
  }

  /**
   * get marker class dynamicly
   */
  get markerClass() {
    return this.deactivated ? "marker-deactivated" : "marker-activated";
  }

  /**
   * calculates the marker style
   * @param marker
   */
  calculateStyle(marker: IMarker) {
    const canvas = document.getElementById("markerImage");
    let rect = undefined;
    if (canvas) {
      rect = canvas.getBoundingClientRect();
    } else {
      this.$log.error("Image not found");
      return;
    }
    const left = marker.left * rect.width;
    const top = marker.top * rect.height;

    const markerColor = marker.color || "#4283ff";

    return `
      position: absolute;
      top: ${top}px;
      left: ${left}px;
      transform: translate(-50%, -50%);
      width: ${this.markerDiameter}px;
      height: ${this.markerDiameter}px;
      font-size: ${this.markerFontSize};
      background-color: ${markerColor};
    `;
  }

  addMarker(event: MouseEvent | TouchEvent) {
    if (this.deactivated) {
      return;
    }

    const canvas = document.getElementById("markerImage");
    let rect = undefined;
    if (canvas) {
      rect = canvas.getBoundingClientRect();
    } else {
      this.$log.error("Image not found");
      return;
    }

    let mouseXPos = 0;
    let mouseYPos = 0;

    if (event instanceof MouseEvent) {
      mouseXPos = event.clientX - rect.left;
      mouseYPos = event.clientY - rect.top;
    } else if (event instanceof TouchEvent && event.changedTouches.length > 0) {
      mouseXPos = event.changedTouches[0].clientX - rect.left;
      mouseYPos = event.changedTouches[0].clientY - rect.top;
    }

    const top = mouseYPos / rect.height;
    const left = mouseXPos / rect.width;

    if (top > 1 || left > 1 || top < 0 || left < 0) {
      this.$log.debug("Marker outside image");
      return;
    }

    this.markerCreated({ top, left });
  }

  /**
   * Event that is fired if a marker is created
   * @param marker
   */
  markerCreated(marker: IMarker | undefined) {
    if (marker) {
      this.$emit("markerCreated", marker);
    }
  }

  /**
   * Event that is fired if a marker is clicked
   * @param marker
   */
  markerClicked(marker: IMarker | undefined) {
    if (!this.deactivated) {
      return;
    }
    if (marker) {
      this.$emit("markerClicked", marker);
    }
  }

  /**
   * Handles mouse down to trigger magnifier after a delay
   */
  onMouseDown(event: MouseEvent) {
    this.handlePointerStart(event);
  }

  /**
   * Handles touch start to trigger magnifier after a delay
   */
  onTouchStart(event: TouchEvent) {
    if (this.deactivated) {
      return;
    }
    // Prevent scrolling on touch start
    event.preventDefault();
    this.handlePointerStart(event);
  }

  /**
   * Shared logic for pointer start events
   */
  handlePointerStart(event: MouseEvent | TouchEvent) {
    if (this.deactivated) {
      return;
    }
    this.magnifierTimeout = window.setTimeout(() => {
      this.showMagnifier = true;
      if (event instanceof MouseEvent) {
        this.onMouseMove(event);
      } else if (event instanceof TouchEvent) {
        this.onTouchMove(event);
      }
    }, 500); // Delay showing magnifier by 0.5 seconds
  }

  /**
   * Handles mouse up to hide the magnifier
   */
  onMouseUp(event: MouseEvent) {
    this.handlePointerEnd();
    this.addMarker(event);
  }

  /**
   * Handles touch end to hide the magnifier
   */
  onTouchEnd(event: TouchEvent) {
    if (this.deactivated) {
      return;
    }
    // Prevent scrolling on touch start
    event.preventDefault();
    this.handlePointerEnd();
    this.addMarker(event);
  }

  /**
   * Shared logic for pointer end events
   */
  handlePointerEnd() {
    if (this.magnifierTimeout) {
      clearTimeout(this.magnifierTimeout);
    }
    this.showMagnifier = false;
  }

  /**
   * Updates magnifier position and background on mouse movement
   * @param event
   */
  onMouseMove(event: MouseEvent) {
    this.updateMagnifierPosition(event);
  }

  /**
   * Updates magnifier position and background on touch movement
   * @param event
   */
  onTouchMove(event: TouchEvent) {
    if (this.deactivated) {
      return;
    }
    // Prevent scrolling on touch start
    event.preventDefault();
    this.updateMagnifierPosition(event);
  }

  // Create a delay function that returns a promise
  delay(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  /**
   * Shared logic to update magnifier position
   * @param event
   */
  updateMagnifierPosition(event: MouseEvent | TouchEvent) {
    if (!this.showMagnifier || this.deactivated) {
      return;
    }

    const canvas = document.getElementById("markerImage");
    if (!canvas) {
      this.$log.error("Image not found");
      return;
    }

    const rect = canvas.getBoundingClientRect();

    let mouseXPos = 0;
    let mouseYPos = 0;
    let xAbsolut = 0;

    if (event instanceof MouseEvent) {
      xAbsolut = event.clientX;
      mouseXPos = event.clientX - rect.left;
      mouseYPos = event.clientY - rect.top;
    } else if (event instanceof TouchEvent && event.touches.length > 0) {
      xAbsolut = event.touches[0].clientX;
      mouseXPos = event.touches[0].clientX - rect.left;
      mouseYPos = event.touches[0].clientY - rect.top;
    }

    this.magnifierPosition.x = mouseXPos;
    this.magnifierPosition.y = mouseYPos;

    const windowWidth = window.innerWidth;

    /**
     * Limit the max size of the magnifier
     */
    if (windowWidth / 2 < 200) {
      this.magnifierSize = windowWidth / 2 - 10;
    } else {
      this.magnifierSize = 200;
    }

    /**
     * calculate the xOffset (left or right)
     */
    if (xAbsolut < windowWidth / 2) {
      /**
       * magnifier position is left
       */
      return (this.magnifierPosition.xOffset = -1);
    } else {
      /**
       * magnifier position is right
       */
      return (this.magnifierPosition.xOffset = 1);
    }
  }

  /**
   * Computes the magnifier style dynamically
   */
  get magnifierStyle() {
    const canvas = document.getElementById("markerImage");
    const rect = canvas ? canvas.getBoundingClientRect() : { width: 0, height: 0 };

    return {
      position: "absolute",
      top: `${this.magnifierPosition.y - this.magnifierSize / 2}px`,
      left: `${this.magnifierPosition.x - (this.magnifierPosition.xOffset * this.magnifierSize) / 2}px`,
      width: `${this.magnifierSize}px`,
      height: `${this.magnifierSize}px`,
      borderRadius: "50%",
      border: "3px solid #ccc",
      boxShadow: "0 0 5px #000",
      pointerEvents: "none",
      backgroundColor: "#fff", // Set to white
      backgroundImage: `url(${this.src})`,
      backgroundSize: `${rect.width * this.magnificationFactor}px ${rect.height * this.magnificationFactor}px`,
      backgroundRepeat: "no-repeat",
      backgroundPositionX: `${-(this.magnifierPosition.x * this.magnificationFactor - this.magnifierSize / 2)}px`,
      backgroundPositionY: `${-(this.magnifierPosition.y * this.magnificationFactor - this.magnifierSize / 2)}px`
    };
  }
}
