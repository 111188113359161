





























import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import LatestEntriesCardList from "@/components/cards/LatestEntriesCardList.vue";
import { IListItem } from "@/lib/interfaces/list-item.interface";
import { simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";

@Component({ components: { LatestEntriesCardList, LatestEntriesCardEmpty } })
export default class LatestEntriesCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: "blue-grey" })
  loadingColor!: string;

  get reportListRoute() {
    return `/partners/${PartnerModule.partner._id}/report`;
  }

  get latestReports(): IListItem[] {
    if (ReportPaginationModule.paginationList.length > 0) {
      return ReportPaginationModule.paginationList.slice(0, 3).map(report => ({
        id: report._id,
        title: `${simpleDate(report?.timestamps?.created)} - ${report.numberplate} ${
          report.customerName ? report.customerName : ""
        }`.trim(),
        subtitle: `${this.excerptMessage(report.message)}`
      }));
    }
    return [];
  }

  excerptMessage(message: string) {
    return message?.substring(0, 75) + "...";
  }

  route(id: string) {
    const path = this.reportListRoute + `/${id}`;
    this.$router.push({ path: path });
  }
}
