import { AnonymizationModelEnum } from "@/lib/enum/AnonymizationModelEnum.enum";
import { AnonymizationResultQualityEnum } from "@/lib/enum/AnonymizationResultQualityEnum.enum";
import { AnonymizationStatusEnum } from "@/lib/enum/AnonymizationStatus.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IEntity } from "@/lib/utility/data/entity.interface";
import VueI18n from "@/plugins/I18nPlugin";
import anonymizationService from "@/services/ainonymizer/services/anonymizationService";
import {
  AinonymizerAnonymizationConfigViewModelGen,
  AinonymizerAnonymizationStatusLogElementViewModelGen,
  AinonymizerAnonymizationViewModelGen,
  AinonymizerBaseImageGen
} from "@/services/ainonymizer/v1/data-contracts";
import { AnonymizationDataAccessLayer } from "@/store/modules/access-layers/anonymization.access-layer";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ITimestamp, Timestamp } from "./timestamp.entity";

@IsFilterable
class AnonymizationBase implements IEntity<IAnonymization>, AinonymizerAnonymizationViewModelGen {
  /** Id  */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.anonymization.id"
  })
  id: string;

  /** The partnerId */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.anonymization.partnerId"
  })
  partnerId: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.anonymization.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId: string | undefined = "";

  @FilterConfig({
    type: FilterTypes.ENUM,
    displayName: "objects.anonymization.status",
    config: { items: Object.values(AnonymizationStatusEnum) }
  })
  status: "Pending" | "InProgress" | "Completed" | "Failed";

  statusLog: AinonymizerAnonymizationStatusLogElementViewModelGen[];

  config: AinonymizerAnonymizationConfigViewModelGen;

  originalImage?: AinonymizerBaseImageGen | undefined;
  anonymizedImage?: AinonymizerBaseImageGen | undefined;

  @FilterConfig({
    type: FilterTypes.ENUM,
    displayName: "objects.cost.type",
    config: { items: Object.values(AnonymizationResultQualityEnum) }
  })
  resultQuality?: "Undetermined" | "Poor" | "Fair" | "Good" | "Excellent" | undefined;

  /** timestamp */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  file?: File;

  loading = false;

  constructor(data: Partial<AinonymizerAnonymizationViewModelGen>) {
    this.id = data.id ?? "";
    this.partnerId = data.partnerId ?? "";
    this.userId = data.userId ?? "";
    this.status = data.status ?? AnonymizationStatusEnum.PENDING;
    this.statusLog = data.statusLog || [];
    this.config = data.config || {
      face: true,
      numberplate: true,
      model: AnonymizationModelEnum.V1,
      deleteAnonymizedImage: false,
      deleteOriginalImage: false
    };
    this.originalImage = data.originalImage;
    this.anonymizedImage = data.anonymizedImage;
    this.resultQuality = data.resultQuality;
    this.timestamp = new Timestamp(data.timestamp);
  }

  map(data: AinonymizerAnonymizationViewModelGen): void {
    this.id = data.id;
    this.partnerId = data.partnerId;
    this.userId = data.userId || undefined;
    this.status = data.status;
    this.statusLog = data.statusLog;
    this.config = data.config;
    this.originalImage = data.originalImage;
    this.anonymizedImage = data.anonymizedImage;
    this.resultQuality = data.resultQuality;
    this.timestamp = new Timestamp(data.timestamp);
  }

  async create(): Promise<this> {
    if (!this.file) {
      throw new Error("No Image attached");
    }

    const res = await anonymizationService.create(this.partnerId, {
      model: "v1",
      face: this.config.face,
      numberplate: this.config.numberplate,
      deleteAnonymizedImage: this.config.deleteAnonymizedImage,
      deleteOriginalImage: this.config.deleteOriginalImage,
      image: this.file
    });

    this.map(res);

    AnonymizationDataAccessLayer.set(this);

    return this;
  }

  async fetch(): Promise<this> {
    this.loading = true;
    try {
      const res = await anonymizationService.getOne(this.partnerId, this.id);
      this.map(res);
      AnonymizationDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      AnonymizationDataAccessLayer.delete(this);
      throw e;
    }
    this.loading = false;

    return this;
  }

  async update(): Promise<this> {
    this.loading = true;
    try {
      await anonymizationService.update(this.partnerId, this.id, { resultQuality: "Fair" });
    } catch (e) {
      this.loading = false;
      throw e;
    }
    this.loading = false;

    return this;
  }

  get totalTime(): number | null {
    return this.calculateTime(this.statusLog, AnonymizationStatusEnum.PENDING, AnonymizationStatusEnum.COMPLETED);
  }

  get calculationTime(): number | null {
    return this.calculateTime(this.statusLog, AnonymizationStatusEnum.IN_PROGRESS, AnonymizationStatusEnum.COMPLETED);
  }

  get fileName(): string {
    return (
      this.originalImage?.metaData?.originalName ||
      this.anonymizedImage?.metaData?.originalName ||
      this.originalImage?.name ||
      this.anonymizedImage?.name ||
      VueI18n.t("anonymization.noImages").toString()
    );
  }

  get downloadFileName(): string {
    return this.anonymizedImage?.metaData?.originalName || this.anonymizedImage?.name || "unknwon_filename.jpg";
  }

  private calculateTime(
    statusLog: AinonymizerAnonymizationStatusLogElementViewModelGen[],
    startStatus: AnonymizationStatusEnum,
    endStatus: AnonymizationStatusEnum
  ): number | null {
    const startTime = statusLog.find(log => log.status === startStatus)?.time;
    const endTime = statusLog.find(log => log.status === endStatus)?.time;

    if (startTime && endTime) {
      return (new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000; // Convert milliseconds to seconds
    }

    return null;
  }
}

type IAnonymization = AnonymizationBase;
const Anonymization = Filter.createForClass(AnonymizationBase);

export { Anonymization, IAnonymization };
