import ReportService from "@/services/mrfiktiv/services/reportService";
import { MrfiktivSharedReportViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "shared-report",
  store
})
export class SharedReportStore extends VuexModule {
  _sharedReport?: MrfiktivSharedReportViewmodelGen = undefined;

  get sharedReport(): MrfiktivSharedReportViewmodelGen | undefined {
    return this._sharedReport;
  }

  @Action
  async getSharedReport(data: { partnerId: string; reportId: string; accesToken: string }) {
    const sharedReport = await ReportService.getReportBySharedAccessToken(
      data.partnerId,
      data.reportId,
      data.accesToken
    );
    this.context.commit("updateSharedReport", sharedReport);
  }

  @Mutation
  updateSharedReport(sharedReport: MrfiktivSharedReportViewmodelGen) {
    this._sharedReport = sharedReport;
  }
}

export const SharedReportModule = getModule(SharedReportStore);
