import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { UserModule } from "@/store/modules/me-user.store";

export type PredefinedFilterType = { name: string; filter: IPageFilterElement[] };

export const myEventFilter: PredefinedFilterType[] = [
  {
    name: "project.ticket.myTickets",
    filter: [
      new PageFilterElement({
        key: "userId",
        operation: "$eq",
        value: UserModule.userId
      })
    ]
  }
];

export const recurringRootEventFilter: PredefinedFilterType[] = [
  {
    name: "components.FilterCardPagination.predefinedFilter.ticket.closed",
    filter: [
      new PageFilterElement({
        key: "isRecurringRoot",
        operation: "$eq",
        value: "true"
      })
    ]
  }
];
